import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Title from "../components/Title";
import styled from "styled-components";

const MapFrame = styled.iframe`
  width: 100%;
  height: 500px;
  border: 0;
`;

const MapPage = () => {
  const api_key = useStaticQuery(query).strapiMapa.APIKey;
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <Layout pageTitle="Dojazd">
      <Title>Dojazd</Title>
      {isLoaded && (
        <MapFrame
          src={`https://www.google.com/maps/embed/v1/place?zoom=17&language=pl&key=${api_key}&q=place_id:ChIJtQ3wneZq_kYRHLxlb--SMTE`}
        />
      )}
    </Layout>
  );
};

const query = graphql`
  query {
    strapiMapa {
      APIKey
    }
  }
`;

export default MapPage;
